<template>
  <div>
    <c-table
      ref="grid"
      title=""
      tableId="grid"
      topBorderClass="topcolor-orange"
      :columns="grid.columns"
      :data="grid.data"
      :isFullScreen="false"
      :columnSetting="false"
      :filtering="false"
      :usePaging="false"
      :hideBottom="true"
      :isExcelDown="true"
      :isTop="true"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-ghs-1',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
        productCategoryCd: '',
        isSaved: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            fix: true,
            name: 'chemNameKr',
            field: 'chemNameKr',
            label: '물질명(KR)',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            fix: true,
            name: 'chemNameEn',
            field: 'chemNameEn',
            label: '물질명(EN)',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            fix: true,
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.ghs.nation.list;
      this.getHeader();
    },
    getHeader() {
      this.$comm.getComboItems('REGUL_ITEM_NATION_CD').then(_result => {
        this.$_.forEach(_result, _item => {
          this.grid.columns.push({
            name: _item.code.toLowerCase(),
            field: _item.code.toLowerCase(),
            label: _item.codeName,
            align: 'left',
            style: 'width:300px',
            setHeader: true,
            lang: _item.code.toLowerCase(),
            type: 'textarea', // 'html2',
            sortable: false,
          });
        })
        this.getList();
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.$_.forEach(this.grid.data, _item => {
          _item.kr = '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1';
          _item.eu = '특정표적장기 독성(반복 노출) : 구분1';
          _item.us = '자료없음';
          _item.cn = '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1';
          _item.jp = '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2';
          _item.vn = '자료없음';
          _item.cl = '자료없음';
        })
      },);
    },
    getList1() {
      this.grid.data = [
        {
          chemNameKr: 'Diiron trioxide',
          casNo: '1309-37-1',
          kr: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          eu: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          us: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          cn: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          jp: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          vn: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          cl: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
        },
        {
          chemNameKr: '산화규소',
          casNo: '7631-86-9',
          kr: '특정표적장기 독성(반복 노출) : 구분1',
          eu: '특정표적장기 독성(반복 노출) : 구분1',
          us: '특정표적장기 독성(반복 노출) : 구분1',
          cn: '특정표적장기 독성(반복 노출) : 구분1',
          jp: '특정표적장기 독성(반복 노출) : 구분1',
          vn: '특정표적장기 독성(반복 노출) : 구분1',
          cl: '특정표적장기 독성(반복 노출) : 구분1',
        },
        {
          chemNameKr: '산화마그네슘',
          casNo: '1309-48-4',
          kr: '자료없음',
          eu: '자료없음',
          us: '자료없음',
          cn: '자료없음',
          jp: '자료없음',
          vn: '자료없음',
          cl: '자료없음',
        },
        {
          chemNameKr: '산화 알루미늄',
          casNo: '1344-28-1',
          kr: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          eu: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          us: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          cn: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          jp: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          vn: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
          cl: '특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1',
        },
        {
          chemNameKr: '2,4-디클로로나이트로벤젠',
          casNo: '611-06-3',
          kr: '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2',
          eu: '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2',
          us: '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2',
          cn: '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2',
          jp: '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2',
          vn: '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2',
          cl: '급성 독성(경구) : 구분4<br>급성 독성(경피) : 구분3<br>생식독성 : 수유독성<br>특정표적장기 독성(1회 노출) : 구분3(호흡기 자극)<br>특정표적장기 독성(반복 노출) : 구분1<br>만성 수생환경 유해성 : 구분2',
        },
      ]
    }
  }
};
</script>

<style lang="sass">
  .ghsinfodetail
    padding-bottom:10px !important
    margin-top:-20px
</style>